* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: 'Roboto', sans-serif;
}

a {
  text-decoration: none !important;
}

body {
  background-color: #0A0A0A !important;
  padding: 10px;
}

.b-color{
  color: #7F1DFF;
}


header {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100px;
  padding: 50px 2rem;
  background-color: transparent;
  z-index: 9999 !important;
}

nav a {
  margin: 0 1rem;
  color: #fff !important;
  font-weight: 700;
  background: var(--primary-i-vr, #7F1DFF);
  box-shadow: 8px 8px 11px 0px rgba(0, 0, 0, 0.20), -4px -6px 10px 0px rgba(255, 255, 255, 0.10), -5px -3px 5px 0px rgba(0, 0, 0, 0.33) inset, 4px 4px 5px 0px rgba(255, 255, 255, 0.20) inset;
  padding: 5px 10px;
}

nav a:hover {
  color: var() !important;
  box-shadow: rgba(50, 50, 93, 0.25) 0px 30px 60px -12px inset, rgba(0, 0, 0, 0.3) 0px 18px 36px -18px inset;
}

header .nav-btn {
  padding: 5px;
  cursor: pointer;
  background: transparent;
  border: none;
  outline: none;
  color: #fff;
  visibility: hidden;
  opacity: 0;
  font-size: 1.8rem;
}

header div,
nav {
  display: flex;
  align-items: center;
}

.is-sticky {
  position: fixed;
  left: 0;
  width: 100%;
  z-index: 999;
  animation: 500ms ease-in-out 0s normal none 1 running fadeInDown;
  padding: 0px;
  background-color: #00000096;
}

.sec-1 {
  background-image: url('./images/Dark.gif');
  background-repeat: no-repeat;
  background-size: cover;
  height: 622px;
}


.jt {
  text-align: justify !important;
}

.bord {
  border: 2px solid #eeeeee4d !important;
}


.bg-center {
  background: radial-gradient(#eeeeee3b, #00000018);
}

.fs-18 {
  font-size: 18px !important;
}


.sec-3 .news {
  position: relative;
  max-width: 380px;
  flex: 0 0 400px;
}

.sec-3 .news .Email {
  width: 100%;
  position: relative;
  height: 50px;
  padding: 12px 35% 12px 12px;
  border-radius: 50px;
  border: 2px solid #7F1DFF;
  margin-bottom: 0;
}

.sec-3 .news .SubscribeBtn {
  position: absolute;
  right: 0;
  padding: 11px 28px;
  border: 2px solid #7F1DFF;
  color: #fff;
  font-weight: 600;
  border-radius: 39px;
  background: var(--primary-i-vr, #7F1DFF);
  box-shadow: 8px 8px 11px 0px rgba(0, 0, 0, 0.20), -4px -6px 10px 0px rgba(255, 255, 255, 0.10), -5px -3px 5px 0px rgba(0, 0, 0, 0.33) inset, 4px 4px 5px 0px rgba(255, 255, 255, 0.20) inset;
}

button.whats {
  position: fixed;
  top: 42.5%;
  right: 0;
}

button.insta {
  position: fixed;
  top: 62.5%;
  right: 0;
}

button.face {
  position: fixed;
  top: 32.5%;
  right: 0;
}

button.link {
  position: fixed;
  top: 52.5%;
  right: 0;
}

button.twit {
  position: fixed;
  top: 72.5%;
  right: 0;
}

button.discord {
  position: fixed;
  top: 82.5%;
  right: 0;
}


@media only screen and (max-width: 600px) {

  header {
    justify-content: flex-end;
  }

  header .nav-btn {
    visibility: visible;
    opacity: 1;
  }

  header nav {
    position: fixed;
    top: -100vh;
    right: 0;
    height: 100%;
    width: 72%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 1.5rem;
    background: linear-gradient(180deg, #000, #00000073);
    transition: 1s;
  }

  header .responsive_nav {
    transform: translateY(100vh);
  }

  nav .nav-close-btn {
    position: absolute;
    top: 2rem;
    right: 2rem;
    color: #fff !important;
  }

  nav a {
    font-size: 1.2rem;
    color: #fff !important;
  }

}

@media only screen and (max-width: 1376px) {
  .sec-1 {
    height: 800px;
  }

  .sec-1 .container {
    top: 72% !important;
  }
}


@media only screen and (max-width: 576px) {

  .sec-1 {
    background-image: url('./images/mobile.jpg');
    background-size: cover;
    height: 467px;
  }

  .sec-1 img {
    width: 22% !important;
  }

  .sec-2 {
    margin-top: 80px !important;
  }

  .sec-1 .container {
    top: 90% !important;
  }

}

@media only screen and (min-width: 1500px) {
  .sec-1 {
    height: 980px;
  }

  .sec-1 .container {
    top: 75% !important;
  }
}